import { compose } from 'redux';
import { connect } from 'react-redux';

import withCurrentUser from 'dpl/decorators/withCurrentUser';
import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { notificationsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';

export default function withNotificationsCount(WrappedComponent) {
  return compose(
    withCurrentUser,
    __DEPRECATED_defineResources((_, { isLoggedIn }) => ({
      notificationsData: {
        ...notificationsResourceDefinition,
        auto: Boolean(isLoggedIn)
      }
    })),
    connect((_, { notificationsData }) => {
      const {
        fetch: fetchNotifications,
        meta,
        value: { data }
      } = notificationsData;

      let totalNotificationsCount = 0;

      if (meta.isSuccess) {
        const {
          unread_messages_count: unreadMessagesCount,
          breeder_profile_notifications: breederProfileNotifications
        } = data;

        totalNotificationsCount = unreadMessagesCount;

        if (breederProfileNotifications) {
          const {
            unseen_applications_count: unseenApplicationsCount,
            has_unseen_news_items: hasUnseenNewsItems
          } = breederProfileNotifications;

          totalNotificationsCount += unseenApplicationsCount;

          return {
            fetchNotifications,
            hasNotifications: Boolean(totalNotificationsCount),
            totalNotificationsCount,
            notifications: {
              unseenApplicationsCount,
              unreadMessagesCount,
              hasUnseenNewsItems
            }
          };
        }

        return {
          fetchNotifications,
          hasNotifications: Boolean(totalNotificationsCount),
          totalNotificationsCount,
          notifications: { unreadMessagesCount }
        };
      }

      return {
        fetchNotifications,
        hasNotifications: false,
        totalNotificationsCount,
        notifications: {
          unreadMessagesCount: 0
        }
      };
    })
  )(WrappedComponent);
}
