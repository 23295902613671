import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import { pushParams } from 'dpl/actions/query_params';
import { SIGNUP_FORM_TYPES } from 'dpl/shared/constants/shared';

const { SIGNUP } = SIGNUP_FORM_TYPES;

export default function BuyerSignupHeaderButton({ className }) {
  const dispatch = useDispatch();

  function handleSignupClick() {
    dispatch(pushParams({ direct_signup: SIGNUP }, { hash: true }));
  }
  return (
    <button
      className={classnames('BuyerSignupHeaderButton w-100', className)}
      onClick={handleSignupClick}
      data-test="header_signup_button"
      type="button"
    >
      Join to find a dog
    </button>
  );
}

BuyerSignupHeaderButton.propTypes = {
  className: PropTypes.string
};

BuyerSignupHeaderButton.defaultProps = {
  className: null
};
