import React from 'react';
import { connect } from 'react-redux';

import { pushParams } from 'dpl/actions/query_params';
import BreederSignupHeaderButton from 'dpl/components/Header/BreederSignupHeaderButton';

function BreederSignupHeaderButtonsContainer(props) {
  return <BreederSignupHeaderButton {...props} />;
}

export default connect(null, { pushParams })(
  BreederSignupHeaderButtonsContainer
);
