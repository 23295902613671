import React from 'react';
import { compose } from 'redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { contactHelpResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { CONSTRAINT_TYPES } from 'dpl/shared/validations/constraints';
import withFormCapabilities from 'dpl/form/decorators/withFormCapabilities';
import withCurrentUser from 'dpl/decorators/withCurrentUser';
import BreederProfileHelpModal from 'dpl/components/BreederProfile/Index/BreederProfileHelpModal';

function BreederProfileHelpModalContainer(props) {
  return <BreederProfileHelpModal {...props} />;
}

export default compose(
  __DEPRECATED_defineResources(() => ({
    helpRequestsData: contactHelpResourceDefinition()
  })),
  withCurrentUser,
  withFormCapabilities(() => ({}), {
    mapPropsAndStateToValidationSchema: ({ isLoggedIn }, { email }) => {
      const baseValidations = { message_text: CONSTRAINT_TYPES.TEXT };

      if (isLoggedIn) {
        return baseValidations;
      }

      return {
        ...baseValidations,
        first_name: CONSTRAINT_TYPES.NON_EMPTY,
        last_name: CONSTRAINT_TYPES.TEXT,
        email: email ? CONSTRAINT_TYPES.EMAIL : CONSTRAINT_TYPES.TEXT
      };
    },
    onSubmit: (props, formStateForSubmission) =>
      props.helpRequestsData.submit({
        help_request: {
          breed_name: props.breedName,
          ...formStateForSubmission
        }
      })
  })
)(BreederProfileHelpModalContainer);
