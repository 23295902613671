import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function Logo({ isAnimated }) {
  return (
    <svg
      className={classnames('logo mw-100 max-h-100', { animated: isAnimated })}
      style={{ overflow: 'visible' }}
      viewBox="0 -10 460 106"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <use xlinkHref="#gooddog-logo" />
      <path
        fill="currentColor"
        className="tail"
        d="M57.17 14L43.82 26.12c-.73.66-1.14 1-1.56 1a2 2 0 0 1-.86-.22l-3.19 3.9 7.3.94-.09-.07c20.66 2 12-17.54 12-17.54z"
      />
      <path
        fill="currentColor"
        className="tail"
        d="M453.41 14l-13.35 12.1c-.73.66-1.14 1-1.56 1a2 2 0 0 1-.86-.22l-3.2 3.92 7.31.94a.71.71 0 0 1-.09-.07c20.66 2 12-17.54 12-17.54z"
      />
    </svg>
  );
}

Logo.propTypes = {
  isAnimated: PropTypes.bool
};

Logo.defaultProps = {
  isAnimated: false
};
