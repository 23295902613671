import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { SIGNUP_FORM_TYPES } from 'dpl/shared/constants/shared';

const { BREEDER_SIGNUP } = SIGNUP_FORM_TYPES;

export default function BreederSignupHeaderButton({ pushParams, className }) {
  return (
    <button
      className={classnames('BreederSignupHeaderButton w-100', className)}
      onClick={() =>
        pushParams({ direct_signup: BREEDER_SIGNUP }, { hash: true })
      }
      data-test="header_signup_button"
      type="button"
    >
      Join as breeder
    </button>
  );
}

BreederSignupHeaderButton.propTypes = {
  pushParams: PropTypes.func.isRequired,
  className: PropTypes.string
};

BreederSignupHeaderButton.defaultProps = {
  className: null
};
