import React from 'react';

import withMobileMenu from 'dpl/decorators/withMobileMenu';
import GlobalMobileMenuSearchButton from 'dpl/components/GlobalMobileMenu/GlobalMobileMenuSearchButton';

function GlobalMobileMenuSearchButtonContainer(props) {
  return <GlobalMobileMenuSearchButton {...props} />;
}

export default withMobileMenu(GlobalMobileMenuSearchButtonContainer);
