import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { MENU_TYPES } from 'dpl/decorators/withMobileMenu';
import Icon from 'dpl/common/components/Icon';
import NotificationDot from 'dpl/common/components/NotificationDot';

export default function GlobalMobileMenuNavigationButton({
  closeMenu,
  openMobileMenuName,
  openNavigationMenu,
  hasNotifications,
  onChange
}) {
  const isOpen = openMobileMenuName === MENU_TYPES.NAVIGATION;

  useEffect(() => {
    onChange?.(isOpen);
  }, [isOpen]);

  return (
    <div
      className={classnames(
        'GlobalMobileMenuNavigationButton relative mt2 mr0 ml-auto',
        { 'GlobalMobileMenuNavigationButton--open': isOpen }
      )}
    >
      <button
        type="button"
        onClick={isOpen ? closeMenu : openNavigationMenu}
        className="GlobalMobileMenuNavigationButton__button w-100 no-select relative"
        aria-label="user header menu"
        data-test="mobile_header_actions_button"
      >
        <Icon
          className="secondary"
          name={isOpen ? 'fetch-close-lg' : 'fetch-menu'}
        />
        {!isOpen && hasNotifications && <NotificationDot />}
      </button>
    </div>
  );
}

GlobalMobileMenuNavigationButton.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  openMobileMenuName: PropTypes.string,
  openNavigationMenu: PropTypes.func.isRequired,
  hasNotifications: PropTypes.bool,
  onChange: PropTypes.func
};

GlobalMobileMenuNavigationButton.defaultProps = {
  openMobileMenuName: null,
  hasNotifications: false,
  onChange: null
};
