import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FixedScrollWrapper from 'dpl/components/FixedScrollWrapper';
import Logo from 'dpl/components/Logo';

export default class MobileMenu extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    position: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    withHeader: PropTypes.bool,
    zIndex: PropTypes.string,
    disableTransition: PropTypes.bool,
    footer: PropTypes.node
  };

  static defaultProps = {
    position: 'right',
    className: null,
    withHeader: false,
    zIndex: '2147483647',
    disableTransition: false,
    footer: null
  };

  state = { isOpen: this.props.isOpen || false };

  _animationFrameRequestId = null;

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (isOpen !== prevProps.isOpen) {
      this._animationFrameRequestId = window.requestAnimationFrame(() => {
        this.setState({ isOpen });
      });
      document.body.classList[isOpen ? 'add' : 'remove']('no-scroll');
    }
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this._animationFrameRequestId);
    document.body.classList.remove('no-scroll');
  }

  render() {
    const { isOpen } = this.state;
    const {
      children,
      className,
      position,
      withHeader,
      zIndex,
      disableTransition,
      footer
    } = this.props;

    return (
      <div
        style={{ zIndex }}
        className={classnames(
          'MobileMenu bg-white fixed absolute--fill vw-100',
          `MobileMenu--${position}`,
          {
            'MobileMenu--open': isOpen,
            transition: !disableTransition
          }
        )}
      >
        {withHeader && (
          <div className="Header flex items-center justify-center">
            <div className="Header__logo primary">
              <Logo />
            </div>
          </div>
        )}
        <FixedScrollWrapper
          className={classnames('absolute absolute--fill', className, {
            'MobileMenu--withHeader': withHeader
          })}
        >
          {children}
        </FixedScrollWrapper>
        {footer && (
          <div className="absolute bottom-0 w-100 bg-white">{footer}</div>
        )}
      </div>
    );
  }
}
