import PropTypes from 'prop-types';
import React from 'react';

const DOT_SIZE = '8px';

export default function NotificationDot({ top, right }) {
  return (
    <div
      data-test-id="breeder-news-notification-dot"
      className="NotificationDot bg-rubber absolute ba bw1 b--white br-100"
      style={{ top, right, height: DOT_SIZE, width: DOT_SIZE }}
    />
  );
}

NotificationDot.propTypes = {
  top: PropTypes.string,
  right: PropTypes.string
};

NotificationDot.defaultProps = {
  top: '0px',
  right: '0px'
};
