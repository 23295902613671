import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LoadingWrapper from 'dpl/components/LoadingWrapper';
import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import ContactModalSuccess from 'dpl/components/ContactModal/ContactModalSuccess';
import BreederProfileHelpModalForm from 'dpl/components/BreederProfile/Index/BreederProfileHelpModal/BreederProfileHelpModalForm';
import Icon from 'dpl/common/components/Icon';

export default class BreederProfileHelpModal extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({
      meta: PropTypes.shape({
        isError: PropTypes.bool,
        isSuccess: PropTypes.bool
      }).isRequired
    }).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formState: PropTypes.object.isRequired,
    getFormErrors: PropTypes.func.isRequired,
    handleFormFieldChange: PropTypes.func.isRequired,
    handleFormSubmission: PropTypes.func.isRequired,
    isFormSubmitting: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  state = { didSubmit: false };

  handleSubmit = e => {
    e.preventDefault();

    this.props.handleFormSubmission().then(() => {
      this.setState({ didSubmit: true });
    });
  };

  render() {
    const {
      currentUser,
      formState,
      getFormErrors,
      handleFormFieldChange,
      isFormSubmitting,
      isLoggedIn,
      onClose
    } = this.props;

    const { didSubmit } = this.state;

    return (
      <MiniOverlayModal
        className="BreederProfileHelpModal"
        maxWidth="600px"
        onCloseClick={onClose}
      >
        <LoadingWrapper
          isLoading={!currentUser.meta.isError && !currentUser.meta.isSuccess}
        >
          {didSubmit ? (
            <ContactModalSuccess />
          ) : (
            <div>
              <div className="pv4 bb b--light-gray tc">
                <div className="mb4">
                  <Icon
                    className="center"
                    height="56px"
                    name="gooddogavatar"
                    width="56px"
                  />
                </div>
                <p className="f2">Contact a Good Dog Specialist</p>
              </div>
              <div className="ph8 ph20-lg pv8 pv10-lg">
                <BreederProfileHelpModalForm
                  formErrors={getFormErrors()}
                  formState={formState}
                  handleFormFieldChange={handleFormFieldChange}
                  isFormSubmitting={isFormSubmitting}
                  isLoggedIn={isLoggedIn}
                  onSubmit={this.handleSubmit}
                />
              </div>
            </div>
          )}
        </LoadingWrapper>
      </MiniOverlayModal>
    );
  }
}
