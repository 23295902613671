import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ErrorWrapper from 'dpl/form/components/ErrorWrapper';

function isValid(formErrors, formName) {
  return (formErrors[formName] || []).length === 0;
}

export default function BreederProfileHelpModalForm({
  formErrors,
  formState,
  handleFormFieldChange,
  isFormSubmitting,
  isLoggedIn,
  onSubmit
}) {
  let canSubmit = isValid(formErrors, 'message_text');

  if (!isLoggedIn) {
    canSubmit =
      canSubmit &&
      isValid(formErrors, 'first_name') &&
      isValid(formErrors, 'last_name') &&
      isValid(formErrors, 'email');
  }

  return (
    <form className="BreederProfileHelpModalForm" onSubmit={onSubmit}>
      {!isLoggedIn && (
        <Fragment>
          <div className="row mb5">
            <div className="col-12 col-lg-6 mb5 mb0-lg">
              <label htmlFor="first_name">
                <p className="dark-gray mb2 f2 fw-medium">First Name</p>
                <ErrorWrapper errors={formErrors.first_name}>
                  <input
                    name="first_name"
                    type="text"
                    className="w-100 br1 ba b--light-gray ph4 pv2"
                    value={formState.first_name || ''}
                    onChange={handleFormFieldChange}
                  />
                </ErrorWrapper>
              </label>
            </div>
            <div className="col-12 col-lg-6">
              <label htmlFor="last_name">
                <p className="dark-gray mb2 f2 fw-medium">Last Name</p>
                <ErrorWrapper errors={formErrors.last_name}>
                  <input
                    name="last_name"
                    type="text"
                    className="w-100 br1 ba b--light-gray ph4 pv2"
                    value={formState.last_name || ''}
                    onChange={handleFormFieldChange}
                  />
                </ErrorWrapper>
              </label>
            </div>
          </div>
          <div className="mb5">
            <label htmlFor="last_name">
              <p className="dark-gray mb2 f2 fw-medium">Email Address</p>
              <ErrorWrapper errors={formErrors.email}>
                <input
                  name="email"
                  type="text"
                  className="w-100 br1 ba b--light-gray ph4 pv2"
                  value={formState.email || ''}
                  onChange={handleFormFieldChange}
                />
              </ErrorWrapper>
            </label>
          </div>
        </Fragment>
      )}
      <div className="mb5">
        <ErrorWrapper errors={formErrors.message_text}>
          <textarea
            name="message_text"
            type="text"
            className="h4 w-100 br1 ba b--light-gray ph4 pv2"
            value={formState.message_text || ''}
            onChange={handleFormFieldChange}
            placeholder="Tell us more about what you're looking for and how we can help you."
          />
        </ErrorWrapper>
      </div>
      <button
        type="submit"
        disabled={isFormSubmitting}
        className={classnames('pv4 w-100 button button--primary', {
          'button--loading': isFormSubmitting,
          'button--disabled': !canSubmit
        })}
      >
        Send
      </button>
    </form>
  );
}

BreederProfileHelpModalForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formState: PropTypes.object.isRequired,
  handleFormFieldChange: PropTypes.func.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};
