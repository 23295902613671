import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import BreederProfileHelpModalContainer from 'dpl/containers/BreederProfile/Index/BreederProfileHelpModalContainer';

export default function ContactSpecialistButton({
  className,
  contactModalIsOpen,
  toggleOff,
  toggleOn
}) {
  return (
    <Fragment>
      <button className={className} onClick={toggleOn} type="button">
        Contact a Specialist
      </button>
      {contactModalIsOpen && (
        <BreederProfileHelpModalContainer onClose={toggleOff} />
      )}
    </Fragment>
  );
}

ContactSpecialistButton.propTypes = {
  className: PropTypes.string,
  contactModalIsOpen: PropTypes.bool.isRequired,
  toggleOff: PropTypes.func.isRequired,
  toggleOn: PropTypes.func.isRequired
};

ContactSpecialistButton.defaultProps = {
  className: 'fw-medium f2 white'
};
