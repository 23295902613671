import { PAYMENT_PAGE_TYPES } from 'dpl/shared/constants/breeder_app';
import { QUERY_KEY_PREFIXES } from 'dpl/shared/fetching/utils/constants';

export function paymentRequestV2QueryDefinition(
  breederProfileId,
  status = PAYMENT_PAGE_TYPES.PENDING,
  limit
) {
  return {
    params: { status, limit },
    prefix: QUERY_KEY_PREFIXES.BREEDER_APP_PAYMENTS,
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_requests/v2.json`
  };
}

export function paymentSourcesQueryDefinition() {
  return {
    url: '/api/options/payment_sources.json'
  };
}

export function breederAppRequestRefundQueryDefinition(
  breederProfileId,
  paymentRequestId
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_requests/${paymentRequestId}/refund_requests/new`,
    defaultValue: {
      refund_request: {
        refundable_amount_in_dollars: '0',
        amount_in_cents: '0',
        notes: null
      }
    }
  };
}

export function breederAppRequestRefundMutationsDefinition(
  breederProfileId,
  paymentRequestId
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_requests/${paymentRequestId}/refund_requests.json`,
    method: 'POST'
  };
}

export function breederAppCreatePaymentRequestMutationDefinition(
  breederProfileId
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_requests.json`,
    method: 'POST'
  };
}

export function breederAppPaymentSummaryQueryDefinition(breederProfileId) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_summary.json`,
    prefix: QUERY_KEY_PREFIXES.BREEDER_APP_PAYMENT_SUMMARY,
    defaultValue: {
      data: {
        monthly_balance: '0',
        pending_balance: '0',
        total_balance: '0'
      }
    }
  };
}

export function breederAppPaymentSettingsShowQueryDefinition(breederProfileId) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_settings.json`,
    prefix: QUERY_KEY_PREFIXES.BREEDER_APP_PAYMENT_SETTINGS,
    defaultValue: {
      data: {}
    }
  };
}

export function breederAppEditPaymentRequestMutationDefinition(
  breederProfileId,
  paymentRequestId
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_requests/${paymentRequestId}.json`,
    method: 'PATCH'
  };
}

export const getProductBrands = {
  url: '/api/options/product_brands.json',
  defaultValue: []
};
