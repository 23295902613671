import React from 'react';

import withToggle from 'dpl/decorators/withToggle';
import ContactSpecialistButton from 'dpl/components/ContactSpecialistButton';

function ContactSpecialistButtonContainer(props) {
  return <ContactSpecialistButton {...props} />;
}

export default withToggle({ propName: 'contactModalIsOpen' })(
  ContactSpecialistButtonContainer
);
