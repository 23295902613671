import React from 'react';

import initReactApp from 'dpl/util/initReactApp';
import HeaderSearchContainer from 'dpl/containers/HeaderSearchContainer';
import UserHeaderActionsContainer from 'dpl/containers/Header/UserHeaderActionsContainer';
import GlobalMobileMenuContainer from 'dpl/containers/GlobalMobileMenu/GlobalMobileMenuContainer';
import ContactSpecialistButtonContainer from 'dpl/containers/ContactSpecialistButtonContainer';

initReactApp({
  HeaderSearchContainer,
  UserHeaderActionsContainer,
  GlobalMobileMenuContainer,
  // eslint-disable-next-line react/display-name
  SlimUserHeaderActionsContainer: () => <UserHeaderActionsContainer slim />,
  ContactSpecialistButtonContainer
});
