import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { SEARCH_TYPES, ORDERED_SEARCH_TYPES } from './util';

export default function HeaderSearchBreedTypeTitle({ breedType }) {
  if (breedType === SEARCH_TYPES.ALL) {
    return (
      <Fragment>
        <span className="d-none d-xl-inline">{SEARCH_TYPES.ALL}&nbsp;</span>
        Breeders
      </Fragment>
    );
  }

  if (breedType === SEARCH_TYPES.BREED_PROFILES) {
    return <>{breedType}</>;
  }

  return (
    <Fragment>
      {breedType}
      <span className="d-none d-xl-inline">&nbsp;Breeders</span>
    </Fragment>
  );
}

HeaderSearchBreedTypeTitle.propTypes = {
  breedType: PropTypes.oneOf(ORDERED_SEARCH_TYPES).isRequired
};
