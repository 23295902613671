import React from 'react';
import { compose } from 'redux';

import withMobileMenu from 'dpl/decorators/withMobileMenu';
import withNotificationsCount from 'dpl/decorators/withNotificationsCount';
import GlobalMobileMenuNavigationButton from 'dpl/components/GlobalMobileMenu/GlobalMobileMenuNavigationButton';

function GlobalMobileMenuNavigationButtonContainer(props) {
  return <GlobalMobileMenuNavigationButton {...props} />;
}

export default compose(
  withNotificationsCount,
  withMobileMenu
)(GlobalMobileMenuNavigationButtonContainer);
