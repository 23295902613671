import React from 'react';
import PropTypes from 'prop-types';

import { isMobileUA } from 'dpl/shared/utils';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import useNotificationCounts from 'dpl/shared/hooks/useNotificationCounts';
import UserHeaderLoggedInDropdown from 'dpl/components/Header/UserHeaderLoggedInDropdown';
import UserHeaderLoggedOutDropdown from 'dpl/components/Header/UserHeaderLoggedOutDropdown';
import GlobalMobileMenuNavigationButtonContainer from 'dpl/containers/GlobalMobileMenu/GlobalMobileMenuNavigationButtonContainer';

const IS_MOBILE_UA = isMobileUA();

export default function UserHeaderActionsContainer(props) {
  const currentUser = useCurrentUser();
  const notificationCounts = useNotificationCounts();

  const allProps = {
    currentUser,
    ...notificationCounts,
    ...props
  };

  if (IS_MOBILE_UA) {
    return <GlobalMobileMenuNavigationButtonContainer {...allProps} />;
  }

  if (currentUser.isLoggedIn) {
    return <UserHeaderLoggedInDropdown {...allProps} />;
  }

  if (currentUser.isLoggedIn === false) {
    return <UserHeaderLoggedOutDropdown {...allProps} />;
  }

  return null;
}

UserHeaderActionsContainer.propTypes = {
  isLoggedIn: PropTypes.bool
};

UserHeaderActionsContainer.defaultProps = {
  isLoggedIn: null
};
