import { ORDERED_SEARCH_TYPES as _ORDERED_SEARCH_TYPES } from 'dpl/shared/constants/shared';
import { BREED_TYPES, BREED_PROFILES } from 'dpl/components/BreedSearch/util';

export const SEARCH_TYPES = {
  ...BREED_TYPES,
  SHELTERS: _ORDERED_SEARCH_TYPES.SHELTERS,
  BREED_PROFILES
};

export const ORDERED_SEARCH_TYPES = Object.values(SEARCH_TYPES);
