import React from 'react';
import { connect } from 'react-redux';

import { isMobileUA } from 'dpl/shared/utils';
import GlobalMobileMenuSearchButtonContainer from 'dpl/containers/GlobalMobileMenu/GlobalMobileMenuSearchButtonContainer';
import HeaderSearch from 'dpl/components/Header/HeaderSearch';

const IS_MOBILE_UA = isMobileUA();

function HeaderSearchContainer(props) {
  return IS_MOBILE_UA && !window.__DPL_BOT_UA ? (
    <GlobalMobileMenuSearchButtonContainer {...props} />
  ) : (
    <HeaderSearch {...props} />
  );
}

export default connect(({ server }) => ({
  initialQuery: window.__DPL_BOT_UA ? '' : server.breedName
}))(HeaderSearchContainer);
